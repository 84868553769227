import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const HeroNotch = styled.img`
  display: block;
  position: absolute;
  z-index: 3;
  width: 159px;
  height: 150px;
  bottom: -40px;
  left: 50%;
  margin-left: 132px;

  ${breakpoint('xs', 'mobile')`
    display: none;
  `}

  ${breakpoint('mobile', 'desktop')`
    margin-left: 40px;
  `}
`;

const StyledHero = styled.div`
  background-image: url('${props => (props.theme.dark ? '/static/images/home-hero-bg-invert.png' : '/static/images/home-hero-bg.png')}');
  background-color: ${props => props.theme.primaryInvert};
  min-height: 200px;
  background-size: cover;
  background-position: center center;
  position: relative;

  > .container {
    padding-bottom: 106px;
    padding-top: 168px;
    overflow: hidden;

    ${breakpoint('mobile', 'tablet')`
      padding-top: 68px;
    `}

    ${breakpoint('xs', 'mobile')`
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 25px;
      padding-bottom: 25px;
    `}
  }
`;

const HeroTitle = styled.h1`
  font-size: 31px;
  font-weight: bold;
  color: ${props => props.theme.primary};
  margin-bottom: 11px;
  text-transform: uppercase;
  text-align: center;

  ${breakpoint('xs', 'mobile')`
    font-size: 28px;
  `}
`;

const HeroSubtitle = styled.h2`
  font-size: 17px;
  text-align: center;
  line-height: 25px;
  margin-bottom: 40px;
`;

const HeroContent = styled.div`
  text-align: center;
  margin-bottom: 114px;

  .content {
    line-height: 28px;

    strong {
      color: ${props => props.theme.primary};
    }

    p {
      margin-bottom: 0;
    }
  }

  ${breakpoint('xs', 'mobile')`
    margin-bottom: 25px;
  `}
`;

const PriceTable = styled.table`
  margin-bottom: 30px;

  ${breakpoint('xs', 'mobile-small')`
    font-size: 14px;
  `}

  tr {
    td {
      padding-right: 18px;
      vertical-align: middle;

      ${breakpoint('xs', 'mobile-small')`
        padding-right: 8px;
        font-size: 1.1rem;
      `}

      &:first-child {
        font-weight: bold;
        span {
          display: none;
        }
        ${breakpoint('xs', 'mobile-small')`
          span { display: block; }
        `}
      }

      &:last-child {
        padding-right: 0;
      }
      
      &:nth-child(2) {
        ${breakpoint('xs', 'mobile-small')`
          display: none;
        `}
      }
    }

    &:not(:last-child) {
      td {
        padding-bottom: 17px;
      }
    }
  }
`;

const PriceWrapper = styled.div`
  width: fit-content;
  margin-left: 150px;

  ${breakpoint('widescreen')`
    margin-left: 280px;
  `}

  ${breakpoint('xs', 'mobile')`
    margin-left: auto;
    margin-right: auto;
  `}

  ${breakpoint('mobile', 'desktop')`
    margin-left: 50px;
  `}
`;

const Lock = styled.img`
  width: 324px;
  height: auto;
  position: absolute;
  bottom: -106px;
  left: 50%;
  margin-left: 190px;

  ${breakpoint('mobile', 'desktop')`
    margin-left: 100px;
  `}
`;

export {
  HeroNotch, StyledHero, HeroTitle, HeroSubtitle, HeroContent, PriceTable, PriceWrapper, Lock,
};
