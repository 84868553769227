import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { Columns, Container, Button } from 'react-bulma-components';

import Carousel from 'components/Carousel';
import { Title } from 'components/Typography';

const CLASSNAME = 'referer-section';

// const SLIDER_SETTINGS = {
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 1
//       }
//     },
//     {
//       breakpoint: 1088,
//       settings: {
//         slidesToShow: 2
//       }
//     }
//   ]
// };

const SliderImg = styled.div`
  background-image: url('${({ src }) => src}');
`;

const SlideComponent = (item) => {
  const {
    name, link, image, price,
  } = item;

  return (
    <div key={name.toString()} className={`${CLASSNAME}__slide-outter`}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        className={`${CLASSNAME}__slide`}
      >
        <SliderImg
          className={`${CLASSNAME}__slide__image`}
          src={image}
        />
        <article className={`${CLASSNAME}__slide__content`}>
          <h5>
            {name}
            <span>{price}</span>
          </h5>
        </article>
      </a>
    </div>
  );
};

const carouselOptions = {
  slidesPerPage: 3,
  infinite: true,
  breakpoints: {
    768: {
      slidesPerPage: 1,
    },
    1024: {
      slidesPerPage: 2,
    },
  },
};

const RefererSection = (props) => {
  const { t, theme } = props;
  const slides = t('referer-section.items', { returnObjects: true });

  return (
    <div className={`${CLASSNAME}`}>
      <Container className="column">
        <Columns centered>
          <Columns.Column size={12} className="has-text-centered">
            <Title centered className={`${CLASSNAME}__title`}>{t('referer-section.title')}</Title>
          </Columns.Column>
          <Columns.Column
            tablet={{ size: 12 }}
            desktop={{ size: 10 }}
            className="has-text-centered"
          >
            <div
              className={`${CLASSNAME}__intro`}
              dangerouslySetInnerHTML={{ __html: t('referer-section.intro') }}
            />
          </Columns.Column>
          <Columns.Column size={12} className="has-text-centered">
            <a
              href="https://www.meglepkek.hu/kezdolap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={`${CLASSNAME}__logo`}
                alt="Meglepkék Logo"
                src={theme.dark ? '/static/images/logo-2-invert.png' : '/static/images/logo-2.png'}
              />
            </a>
          </Columns.Column>
          <Columns.Column
            tablet={{ size: 12 }}
            desktop={{ size: 12 }}
            className={`${CLASSNAME}__slider-column`}
          >
            {slides && <Carousel items={slides} ItemComponent={SlideComponent} options={carouselOptions} />}
          </Columns.Column>
          <Columns.Column size={12} className="has-text-centered">
            <Button
              className="is-normal is-other"
              renderAs="a"
              href="https://www.meglepkek.hu/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('referer-section.button-text')}
            </Button>
          </Columns.Column>
        </Columns>
      </Container>
      <div className={`${CLASSNAME}__decor`}>
        <img alt="Meglepkék Logo" src={theme.dark ? '/static/images/tire-decor-invert.png' : '/static/images/tire-decor.png'} />
      </div>
    </div>
  );
};

RefererSection.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    foo: PropTypes.func,
    bar: PropTypes.object,
    fooBar: PropTypes.string,
  }).isRequired,
};

export default withTheme(RefererSection);
