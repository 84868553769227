import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const VideoSectionWrapper = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  background-image: url("${props => (props.theme.dark ? '/static/images/heart-grey-bg-invert.jpg' : '/static/images/heart-grey-bg.jpg')}");
  padding: calc(70px - 0.75rem) 0;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const IframeWrapper = styled.div`
  width: 875px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 10px solid ${props => (props.theme.dark ? '#222222' : 'white')};
  box-shadow: 0 2px 44px 0 rgba(154,154,154,0.50);
  margin-top: 30px;
  margin-bottom: 67px;

  ${breakpoints('xs', 'mobile')`
    margin-bottom: 37px;
  `}
`;

export { VideoSectionWrapper, IframeWrapper };
