import React from 'react';
import PropTypes from 'prop-types';
import { Columns, Container } from 'react-bulma-components';
import Sanitized from 'react-sanitized';
import Link from 'next/link';

import { Title } from 'components/Typography';
import Button from 'components/Button';

import { StyledClockSection, Clock, ClockContent } from './ui';

const ClockSection = (props) => {
  const { t } = props;

  return (
    <StyledClockSection>
      <Container className="column">
        <Columns>
          <Columns.Column className="has-text-centered" tablet={{ size: 5 }} desktop={{ size: 4 }}>
            <Clock src="/static/images/clock.png" />
          </Columns.Column>
          <Columns.Column tablet={{ size: 7 }} desktop={{ size: 8 }}>
            <Title>{t('clock.title')}</Title>
            <ClockContent>
              <Sanitized className="content" html={t('clock.content')} wrapperTag="div" />
            </ClockContent>
            <Link href="/order" as="/lakat-megrendelese">
              <Button>{t('clock.button-text')}</Button>
            </Link>
          </Columns.Column>
        </Columns>
      </Container>
    </StyledClockSection>
  );
};

ClockSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ClockSection;
