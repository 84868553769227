import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const SocialSectionWrapper = styled.div`
  background: ${props => props.theme.primaryInvert};
  padding: calc(70px - 0.75rem) 0;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const SocialSectionContent = styled.div`
  margin-bottom: 52px;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;

  ${breakpoints('xs', 'mobile')`
    margin-bottom: 30px;
  `}

  iframe {
    margin-left: auto;
    margin-right: auto;
    height: 506px;
  }
`;

export { SocialSectionWrapper, SocialSectionContent };
