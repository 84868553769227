/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const CarouselNav = styled.span`
  ${props => props.theme.icomoon};
  transition: ${props => props.theme.transition};
  opacity: 0.79;
  background: white;
  box-shadow: 0 0 24px 0 rgba(0,0,0,0.18);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(${props => (props.side === 'left' ? '180deg' : '0deg')});
  margin-right: ${props => (props.side === 'left' ? '22px' : '0')};
  margin-left: ${props => (props.side === 'right' ? '22px' : '0')};

  &:hover {
    opacity: 1;
  }

  &:before {
    content: "\\\e901";
    position: relative;
    right: -2px;
    font-size: 20px;
    color: ${props => props.theme.primary};
  }

  ${breakpoints('xs', 'mobile')`
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: 15px;
    z-index: 2;

    ${props => props.side === 'left' && css`
      left: inherit;
      right: 50%;
      margin-right: 15px;
    `}
  `}
`;

export { CarouselNav };
