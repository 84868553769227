import React from 'react';
import PropTypes from 'prop-types';

import Sanitized from 'react-sanitized';
import { Columns } from 'react-bulma-components';

import {
  LockWrapper, LockContentWrapper, LockTitle, LockContent, Lock,
} from './ui';

const LockItem = (props, index) => {
  const {
    name, size, content, image,
  } = props;

  return (
    <Columns.Column key={`lock-product-${name}`}>
      <LockWrapper position={index + 1}>
        <LockContentWrapper position={index + 1}>
          <LockTitle>
            {name}
            <br />
            {size}
          </LockTitle>
          <LockContent>
            <Sanitized className="content" html={content} wrapperTag="div" />
          </LockContent>
        </LockContentWrapper>
        <Lock src={image} />
      </LockWrapper>
    </Columns.Column>
  );
};

LockItem.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default LockItem;
