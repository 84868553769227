import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const ListSectionWrapper = styled.div`
  background: ${props => props.theme.primaryInvert};
  padding: calc(70px - 0.75rem) 0;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const ListSectionContent = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
`;

export { ListSectionWrapper, ListSectionContent };
