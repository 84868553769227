import React from 'react';
import PropTypes from 'prop-types';
import Sanitized from 'react-sanitized';
import Link from 'next/link';

import { Container } from 'react-bulma-components';

import { Title } from 'components/Typography';
import Button from 'components/Button';

import { ListSectionWrapper, ListSectionContent } from './ui';

const ListSection = (props) => {
  const { t } = props;

  return (
    <ListSectionWrapper>
      <Container className="column">
        <Title centered>
          {t('list-section.title')}
        </Title>
        <ListSectionContent>
          <Sanitized className="content" html={t('list-section.content')} wrapperTag="div" />
        </ListSectionContent>
        <div className="has-text-centered">
          <Link href="/order" as="/lakat-megrendelese">
            <Button>
              {t('list-section.button-text')}
            </Button>
          </Link>
        </div>
      </Container>
    </ListSectionWrapper>
  );
};

ListSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ListSection;
