import React from 'react';

import PropTypes from 'prop-types';
import Sanitized from 'react-sanitized';
import { Container } from 'react-bulma-components';

import { Title } from 'components/Typography';

import {
  ShippingSectionWrapper,
  ShippingSectionContent,
  ShippingSectionIcons,
  ShippingSectionElem,
  ShippingSectionIcon,
} from './ui';

const ShippingSection = (props) => {
  const { t } = props;
  const infos = t('shipping-section.infos', { returnObjects: true });

  return (
    <ShippingSectionWrapper>
      <Container className="column">
        <Title centered>
          {t('shipping-section.title')}
        </Title>
        <ShippingSectionIcons>
          {Array.isArray(infos) && infos.map((info) => {
            const { title, icon } = info;

            return (
              <ShippingSectionElem key={title}>
                <ShippingSectionIcon src={icon} />
                {title}
              </ShippingSectionElem>
            );
          })}
        </ShippingSectionIcons>
        <ShippingSectionContent>
          <Sanitized className="content" html={t('shipping-section.content')} wrapperTag="div" />
        </ShippingSectionContent>
      </Container>
    </ShippingSectionWrapper>
  );
};

ShippingSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ShippingSection;
