import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const SlideShowWrapper = styled.div`
  padding: calc(77px - 0.75rem) 0.75rem;
  background: ${props => props.theme.primaryInvert};

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0.75rem;
  `}
`;

const CarouselWrapper = styled.div`
  .BrainhubCarousel {
    margin: -25px -13px;
  }

  .carousel-nav-left {
    margin-right: -38px;
    z-index: 2;
    position: relative;
  }

  .carousel-nav-right {
    margin-left: -38px;
    z-index: 2;
    position: relative;
  }
`;

const SlideImage = styled.div`
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.50);
  padding-bottom: 56%;
  width: 100%;
  margin: 25px 13px;
`;

export { SlideShowWrapper, CarouselWrapper, SlideImage };
