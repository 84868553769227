import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

import { Title } from 'components/Typography';

const InfoSectionWrapper = styled.div`
  background-image: ${props => (props.theme.dark ? 'linear-gradient(to bottom right, rgba(0, 0, 0, 0.83), #4d4d4d)' : 'linear-gradient(135deg, #FFFFFF 0%, #ECECEC 100%)')};
  padding-top: calc(92px - 0.75rem);
  padding-bottom: calc(92px - 0.75rem);

  ${Title} {
    width: 450px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  ${breakpoints('xs', 'mobile')`
    padding-top: calc(35px - 0.75rem);
    padding-bottom: calc(35px - 0.75rem);
  `}
`;

const InfoLead = styled.div`
  margin-top: 30px;
  margin-bottom: 57px;
  width: 998px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const InfoContent = styled.div`
  width: 1007px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  .content ul {
    column-count: 2;
    column-gap: 50px;

    ${breakpoints('xs', 'mobile')`
      column-count: 1;
    `}
  }
`;

export {
  InfoSectionWrapper, InfoLead, InfoContent,
};
