import styled from 'styled-components';

const LabelTitle = styled.h2`
  font-size: 20px;
  text-align: center;
  margin-bottom: 17px;
  font-weight: 900;

  span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
  }
`;

export default LabelTitle;
