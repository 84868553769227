import React from 'react';
import PropTypes from 'prop-types';
import Sanitized from 'react-sanitized';

import { StyledSlideComponent, SlideContent, Ratings } from './ui';

const SlideComponent = (props) => {
  const { content } = props;

  return (
    <StyledSlideComponent>
      <SlideContent>
        <Sanitized className="content" html={content} wrapperTag="div" />
      </SlideContent>
      <Ratings>
        <span />
        <span />
        <span />
        <span />
        <span />
      </Ratings>
    </StyledSlideComponent>
  );
};

SlideComponent.propTypes = {
  content: PropTypes.string.isRequired,
};

export default SlideComponent;
