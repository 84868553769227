import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const StyledClockSection = styled.div`
  position: relative;
  z-index: 2;
  background-image: ${props => (props.theme.dark ? 'linear-gradient(to bottom right, rgba(0, 0, 0, 0.83), #4d4d4d)' : 'linear-gradient(135deg, #FFFFFF 0%, #ECECEC 100%)')};
  box-shadow: 0 2px 54px 0 rgba(0,0,0,0.37);
  padding-top: calc(38px - 0.75rem);
  padding-bottom: calc(38px - 0.75rem);

  ${breakpoints('xs', 'mobile')`
    padding-top: calc(10px - 0.75rem);
  `}
`;

const Clock = styled.img`
  width: 300px;
  height: auto;
  margin: -50px auto;

  ${breakpoints('xs', 'mobile')`
    margin-top: -20px;
    margin-bottom: -35px;
  `}
`;

const ClockContent = styled.div`
  margin-bottom: 30px;

  .content {
    font-size: 14px;
    line-height: 25px;
  }
`;

export { StyledClockSection, Clock, ClockContent };
