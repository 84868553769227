import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Container } from 'react-bulma-components';
import Sanitized from 'react-sanitized';

import ButtonNormal from 'components/ButtonNormal';

import {
  HistorySectionWrapper,
  HistorySectionContent,
  HistorySectionTitle,
  HistorySectionButtonWrapper,
} from './ui';

const HistorySection = (props) => {
  const { t } = props;
  const [contentOpen, setContentOpen] = useState(false);

  const toggleContent = () => setContentOpen(!contentOpen);

  return (
    <HistorySectionWrapper>
      <Container className="column">
        <HistorySectionTitle centered>
          <Sanitized html={t('history-section.title')} />
        </HistorySectionTitle>
        <HistorySectionContent open={contentOpen}>
          <Sanitized className="content" html={t('history-section.content')} wrapperTag="div" />
        </HistorySectionContent>
        <HistorySectionButtonWrapper>
          <ButtonNormal onClick={toggleContent} className="is-primary is-outlined">
            { contentOpen ? t('history-section.button-text-close') : t('history-section.button-text')}
          </ButtonNormal>
        </HistorySectionButtonWrapper>
      </Container>
    </HistorySectionWrapper>
  );
};

HistorySection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default HistorySection;
