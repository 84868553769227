import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

import { Title } from 'components/Typography';

const StyledDiscountSection = styled.div`
  position: relative;
  z-index: 2;
  background-image: url("${props => (props.theme.dark ? '/static/images/heart-gradient-yellow-bg-invert.png' : '/static/images/heart-gradient-yellow-bg.png')}");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 54px 0 rgba(0,0,0,0.37);
  padding-top: calc(60px - 0.75rem);
  padding-bottom: calc(60px - 0.75rem);

  ${breakpoints('xs', 'mobile')`
    padding-top: calc(30px - 0.75rem);
  `}
`;


const DiscountTitle = styled(Title)`
  font-size: 41px;
  margin-bottom: 0;
  width: 385px;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${breakpoints('xs', 'mobile')`
    font-size: 31px;
  `}
`;

const DiscountContent = styled.div`
  margin-bottom: 25px;
  width: 377px;
  max-width: 100%;

  .content {
    font-size: 15px;
    line-height: 26px;
  }
`;

export { StyledDiscountSection, DiscountContent, DiscountTitle };
