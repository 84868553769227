import React from 'react';
import PropTypes from 'prop-types';

const Buttons = (props) => {
  const { children } = props;

  return (
    <div className="buttons">
      {children}
    </div>
  );
};

Buttons.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Buttons;
