import styled, { css } from 'styled-components';

const getSize = (size) => {
  switch (size) {
    case ('small'):
      return css`
        width: 23px;
        height: 23px;
        font-size: 9px;
      `;

    case ('medium'):
      return css`
        width: 35px;
        height: 35px;
        font-size: 12px;
      `;

    default:
      return css`
        width: 51px;
        height: 51px;
        font-size: 17px;
      `;
  }
};

const StyledButtonArrow = styled.a`
  ${props => props.theme.icomoon};
  transition: ${props => props.theme.transition};
  border-radius: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  background: ${props => (props.theme.dark ? '#222222' : '#FFFFFF')};
  box-shadow: 0 12px 28px 0 rgba(0,0,0,0.50);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.primary};
  ${props => getSize(props.size)};

  ${props => props.hoverEffect && css`
    will-change: transform;

    &:hover {
      transform: scale(1.05);
    }
  `}

  &:before {
    content: '\\\e902';
  }

  .button & {
    position: absolute;
    top: -1px;
    left: -1px; 
  }

  .button:hover > & {
    margin-left: 100%;
    transform: translateX(calc(-100% + 1px));
  }

  ${props => props.invert && css`
    background-color: ${p => p.theme.primary};
    color: ${p => (p.theme.dark ? '#222222' : '#FFFFFF')};
  `}
`;

const StyledButton = styled.a`
  transition: ${props => props.theme.transition};
  min-height: 50px;
  background-color: ${props => props.theme.primary};
  padding-right: 25px;
  padding-left: 61px;
  border-radius: 25px;
  font-weight: bold;
  font-size: 14px;
  color: ${props => (props.theme.dark ? '#222222' : '#FFFFFF')};
  white-space: pre-wrap;
  word-wrap: break-word;
  border: none;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  cursor: pointer;
  outline: none;
  position: relative;

  &:hover {
    color: ${props => (props.theme.dark ? '#222222' : '#FFFFFF')};
    padding-left: 25px;
    padding-right: 61px;
    background-color: ${props => props.theme.primaryDarker};
  }

  ${props => props.invert && css`
    background-color: ${p => (p.theme.dark ? '#222222' : '#FFFFFF')};
    color: ${p => p.theme.primary};

    &:hover {
      color: ${p => p.theme.primary};
      background-color: ${p => (p.theme.dark ? '#222222' : '#FFFFFF')};
    }
  `}
`;

export { StyledButtonArrow, StyledButton };
