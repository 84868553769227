import styled, { css } from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

import { Title } from 'components/Typography';

const HistorySectionWrapper = styled.div`
  background-color: ${props => props.theme.primaryInvert};
  padding: calc(70px - 0.75rem) 0;
  background-image: url("/static/images/history-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const HistorySectionTitle = styled(Title)`
  width: 417px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 47px;
`;

const HistorySectionContent = styled.div`
  margin-bottom: 52px;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 314px;
  overflow: hidden;

  ${props => props.open && css`
    max-height: none;
  `}

  ${breakpoints('xs', 'mobile')`
    margin-bottom: 30px;
  `}

  iframe {
    margin-left: auto;
    margin-right: auto;
    height: 506px;
  }
`;

const HistorySectionButtonWrapper = styled.div`
width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
`;

export {
  HistorySectionWrapper,
  HistorySectionContent,
  HistorySectionTitle,
  HistorySectionButtonWrapper,
};
