import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";

import { Container } from "react-bulma-components";

import Button from "components/Button";
import FacebookPageEmbed from "./FacebookPageEmbed";

import { SocialSectionWrapper, SocialSectionContent } from "./ui";

const SocialSection = (props) => {
  const { t } = props;
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (document.innerWidth <= 768) {
      setMobile("mobile");
    } else {
      setMobile("desktop");
    }
  }, []);

  return (
    <SocialSectionWrapper>
      <Container className="column">
        <SocialSectionContent>
          {mobile &&
            (mobile === "mobile" ? (
              <FacebookPageEmbed width={320} />
            ) : (
              <FacebookPageEmbed width={500} />
            ))}
        </SocialSectionContent>
        <div className="has-text-centered">
          <Link href="/order" as="/lakat-megrendelese">
            <Button>{t("social-section.button-text")}</Button>
          </Link>
        </div>
      </Container>
    </SocialSectionWrapper>
  );
};

SocialSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SocialSection;
