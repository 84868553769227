import React from 'react';
import PropTypes from 'prop-types';

const FacebookPageEmbed = (props) => {
  const { width } = props;

  return (
    <iframe
      src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegyenlakatunk%2F&tabs=timeline&width=${width}&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2192624620999478`}
      height="500"
      style={{ border: 'none', overflow: 'hidden', width }}
      scrolling="no"
      frameBorder="0"
      title="Szerelemlakat"
      allowtransparency="true" // eslint-disable-line
      allow="encrypted-media"
    />
  );
};

FacebookPageEmbed.propTypes = {
  width: PropTypes.number.isRequired,
};

export default FacebookPageEmbed;
