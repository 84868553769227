import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const TestimonialWrapper = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  background-image: url("/static/images/heart-gradient-bg.jpg");
  padding: calc(70px - 0.75rem) 0;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const StyledSlideComponent = styled.div`
  background: ${props => (props.theme.dark ? props.theme.primaryInvert : '#F5F2F1')};
  border: 4px solid  ${props => (props.theme.dark ? '#494949' : '#CECECE')};
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.23);
  border-radius: 6px;
  padding: 45px 39px 33px;
  margin: 40px 20px;
  min-height: calc(100% - 80px);
  display: flex;
  flex-direction: column;

  ${breakpoints('xs', 'mobile')`
    padding: 25px;
  `}
`;

const SlideContent = styled.div`
  font-style: italic;
  line-height: 26px;
  font-size: 15px;
  flex: 1;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 38px;

    ${breakpoints('xs', 'mobile')`
      margin-bottom: 15px;
    `}
  }
`;

const Ratings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.primary};

  span {
    ${props => props.theme.icomoon};
    display: block;
    font-size: 25px;

    &:before {
      content: "\\\e904";
    }

    &:not(:last-child) {
      margin-right: 7px;
    }
  }
`;

const CarouselWrapper = styled.div`

  ${breakpoints('xs', 'mobile')`
    .BrainhubCarousel {
      margin-top: 65px;
    }

    .BrainhubCarousel__track {
      margin-top: -24px !important;
    }
  `}
`;

export {
  TestimonialWrapper, StyledSlideComponent, SlideContent, Ratings, CarouselWrapper,
};
