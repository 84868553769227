import React from 'react';
import PropTypes from 'prop-types';
import { SlideImage } from './ui';

const SlideComponent = (props) => {
  const { image } = props;

  return (
    <SlideImage src={image} />
  );
};

SlideComponent.propTypes = {
  image: PropTypes.string.isRequired,
};

export default SlideComponent;
