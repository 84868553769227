import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const ShippingSectionWrapper = styled.div`
  background: ${props => props.theme.primaryInvert};
  padding: calc(100px - 0.75rem) 0;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const ShippingSectionContent = styled.div`
  margin-top: 50px;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  font-size: 16px;
`;


const ShippingSectionIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 53px;
  width: 1080px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  > div:nth-child(1), 
  > div:nth-child(2) {
    width: 50%;
  }
`;

const ShippingSectionElem = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
  display: flex;
  align-items: center;
  margin-bottom: 47px;
  width: 330px;
  max-width: 100%;
  justify-content: center;

  ${breakpoints('xs', 'mobile')`
    justify-content: flex-start;
    width: 100% !important;

    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const ShippingSectionIcon = styled.img`
  margin-right: 13px;
  max-width: 100%;

  ${breakpoints('xs', 'mobile')`
    max-width: 43px;
    height: auto;
    margin-right: 18px;
  `}
`;


export {
  ShippingSectionWrapper,
  ShippingSectionContent,
  ShippingSectionIcons,
  ShippingSectionElem,
  ShippingSectionIcon,
};
