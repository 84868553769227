import React from 'react';
import PropTypes from 'prop-types';

import { IframeWrapper, StyledIframe } from './ui';

const Iframe = (props) => {
  const { src } = props;

  return (
    <IframeWrapper>
      <StyledIframe src={src} frameBorder="0" {...props} />
    </IframeWrapper>
  );
};

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Iframe;
