import React from 'react';
import PropTypes from 'prop-types';

import ButtonArrow from './ButtonArrow';
import { StyledButton } from './ui';

const Button = (props) => {
  const {
    renderAs, children, invert, ...rest
  } = props;

  return (
    <StyledButton className="button" as={renderAs || 'button'} {...rest} invert={invert}>
      <ButtonArrow invert={invert} />
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  renderAs: PropTypes.string,
  children: PropTypes.node.isRequired,
  invert: PropTypes.bool,
};

Button.defaultProps = {
  renderAs: 'a',
  invert: false,
};

export default Button;
