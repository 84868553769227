import React from 'react';
import PropTypes from 'prop-types';

import { StyledButtonArrow } from './ui';

const ButtonArrow = (props) => {
  const {
    renderAs, ...rest
  } = props;

  return (
    <StyledButtonArrow as={renderAs} {...rest} className="button-arrow" />
  );
};

ButtonArrow.propTypes = {
  renderAs: PropTypes.string,
};

ButtonArrow.defaultProps = {
  renderAs: 'span',
};

export default ButtonArrow;
