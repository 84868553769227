import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bulma-components';

import Carousel from 'components/Carousel';

import SlideComponent from './SlideComponent';

import {
  SlideShowWrapper,
  CarouselWrapper,
} from './ui';

const carouselOptions = {
  slidesPerPage: 3,
  infinite: true,
  breakpoints: {
    768: {
      slidesPerPage: 1,
    },
    1024: {
      slidesPerPage: 2,
    },
  },
};

const SlideShow = (props) => {
  const { t } = props;
  const slides = t('slide-show.slides', { returnObjects: true });

  return (
    <SlideShowWrapper>
      <Container className="column">
        <CarouselWrapper>
          <Carousel items={slides} ItemComponent={SlideComponent} options={carouselOptions} />
        </CarouselWrapper>
      </Container>
    </SlideShowWrapper>
  );
};

SlideShow.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SlideShow;
