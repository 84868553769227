import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { Container, Columns } from 'react-bulma-components';

import { Title } from 'components/Typography';
import Button from 'components/Button';

import { ProductSectionWrapper } from './ui';

import LockItem from './LockItem';

const ProductSection = (props) => {
  const { t } = props;
  const products = t('product-section.products', { returnObjects: true });

  return (
    <ProductSectionWrapper>
      <Container className="column">
        <Columns centered multiline>
          <Columns.Column size={12}>
            <Title centered>
              {t('product-section.title')}
            </Title>
          </Columns.Column>
          <Columns.Column size={10}>
            <Columns>
              {products && Array.isArray(products) && products.map(LockItem)}
            </Columns>
          </Columns.Column>
          <Columns.Column size={12} className="has-text-centered">
            <Link href="/order" as="/lakat-megrendelese">
              <Button>
                {t('product-section.button-text')}
              </Button>
            </Link>
          </Columns.Column>
        </Columns>
      </Container>
    </ProductSectionWrapper>
  );
};

ProductSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ProductSection;
