import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { Container, Columns } from 'react-bulma-components';

import { Title } from 'components/Typography';
import Carousel from 'components/Carousel';
import Button from 'components/Button';

import SlideComponent from './SlideComponent';

import {
  TestimonialWrapper,
  CarouselWrapper,
} from './ui';


const carouselOptions = {
  slidesPerPage: 2,
  infinite: true,
  breakpoints: {
    768: {
      slidesPerPage: 1,
    },
  },
};

const TestimonialSlider = (props) => {
  const { t } = props;
  const slides = t('testimonial-slider.slides', { returnObjects: true });

  return (
    <TestimonialWrapper>
      <Container className="column">
        <Columns centered multiline>
          <Columns.Column size={12}>
            <Title invert centered>{t('testimonial-slider.title')}</Title>
          </Columns.Column>
          <Columns.Column size={10}>
            <CarouselWrapper>
              {slides && <Carousel options={carouselOptions} items={slides} ItemComponent={SlideComponent} />}
            </CarouselWrapper>
            <div className="has-text-centered">
              <Link href="/order" as="/lakat-megrendelese">
                <Button invert>
                  {t('testimonial-slider.button-text')}
                </Button>
              </Link>
            </div>
          </Columns.Column>
        </Columns>
      </Container>
    </TestimonialWrapper>
  );
};

TestimonialSlider.propTypes = {
  t: PropTypes.func.isRequired,
};

export default TestimonialSlider;
