import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { Container } from 'react-bulma-components';

import Iframe from 'components/Iframe';
import { Title } from 'components/Typography';
import Button from 'components/Button';

import { VideoSectionWrapper, IframeWrapper } from './ui';

const VideoSection = (props) => {
  const { t } = props;

  return (
    <VideoSectionWrapper>
      <Container className="column">
        <Title centered>
          {t('video-section.title')}
        </Title>
        <IframeWrapper>
          <Iframe src={t('video-section.url') || ''} />
        </IframeWrapper>
        <div className="has-text-centered">
          <Link href="/order" as="/lakat-megrendelese">
            <Button>
              {t('video-section.button-text')}
            </Button>
          </Link>
        </div>
      </Container>
    </VideoSectionWrapper>
  );
};

VideoSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default VideoSection;
