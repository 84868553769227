import React from 'react';
import PropTypes from 'prop-types';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { CarouselNav } from './ui';

const defaultOptions = {
  arrowLeft: (<CarouselNav className="carousel-nav-left" side="left" />),
  arrowRight: (<CarouselNav className="carousel-nav-right" side="right" />),
  slidesPerScroll: 1,
  addArrowClickHandler: true,
};

const CustomCarousel = (props) => {
  const { items, ItemComponent, options } = props;

  if (!items || !Array.isArray(items)) return null;

  return (
    <Carousel
      {...defaultOptions}
      {...options}
    >
      {items.map((item, i) => <ItemComponent key={`carousel-item-${i.toString()}`} {...item} />)}
    </Carousel>
  );
};

CustomCarousel.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        foo: PropTypes.func,
        bar: PropTypes.object,
        fooBar: PropTypes.string,
      }),
    )]).isRequired,
  ItemComponent: PropTypes.func.isRequired,
  options: PropTypes.shape({
    foo: PropTypes.func,
    bar: PropTypes.object,
    fooBar: PropTypes.string,
  }),
};

CustomCarousel.defaultProps = {
  options: {},
};

export default CustomCarousel;
