import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bulma-components';
import Sanitized from 'react-sanitized';
import Link from 'next/link';

import Button, { ButtonArrow } from 'components/Button';

import {
  HeroNotch,
  StyledHero,
  HeroTitle,
  HeroSubtitle,
  HeroContent,
  PriceTable,
  PriceWrapper,
  Lock,
} from './ui';

const HomeHero = (props) => {
  const { t, priceCategories = [], basePrice } = props;

  return (
    <StyledHero>
      <HeroNotch src="/static/images/notch.png" />
      <Container>
        <HeroTitle>{t('home:hero.title')}</HeroTitle>
        <HeroSubtitle>{t('hero.subtitle')}</HeroSubtitle>
        <HeroContent>
          <Sanitized className="content" html={t('hero.content')} wrapperTag="div" />
        </HeroContent>
        <PriceWrapper>
          <PriceTable>
            <tbody>
              {priceCategories && priceCategories
                .filter(e => e.type === 'lock_type')
                .map(({ id, info, question, prices }) => (
                  <tr key={id}>
                    <td>
                      {question}
                      <span>({info})</span>
                    </td>
                    <td>
                      ({info})
                    </td>
                    <td>
                      {new Intl.NumberFormat('hu-HU').format(prices[0].price + basePrice)} Ft-tól
                    </td>
                    <td>
                      <Link href="/order" as="/lakat-megrendelese">
                        <ButtonArrow hoverEffect size="medium" />
                      </Link>
                    </td>
                  </tr>
                ))}
              {/* <tr>
                <td>
                  Kis lakat
                </td>
                <td>(9cm x 4cm)</td>
                <td>5.490 Ft-tól</td>
                <td>
                  <Link href="/order" as="/lakat-megrendelese">
                    <ButtonArrow hoverEffect size="small" />
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  Hatalmas lakat
                </td>
                <td>(19cm x 8cm)</td>
                <td>11.990 Ft-tól</td>
                <td>
                  <Link href="/order" as="/lakat-megrendelese">
                    <ButtonArrow hoverEffect size="small" />
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  GIGA lakat
                </td>
                <td>(29cm x 19cm)</td>
                <td>24.990 Ft-tól</td>
                <td>
                  <Link href="/order" as="/lakat-megrendelese">
                    <ButtonArrow hoverEffect size="small" />
                  </Link>
                </td>
              </tr> */}
            </tbody>
          </PriceTable>
          <Link href="/order" as="/lakat-megrendelese">
            <Button fullWidth>
              Megveszem ajándékba
            </Button>
          </Link>
        </PriceWrapper>
        <Lock src="/static/images/hero-lock.png" />
      </Container>
    </StyledHero>
  );
};

HomeHero.propTypes = {
  t: PropTypes.func.isRequired,
};

export default HomeHero;
