import styled, { css } from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const ProductSectionWrapper = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  background-image: url("${props => (props.theme.dark ? '/static/images/heart-grey-bg-invert.jpg' : '/static/images/heart-grey-bg.jpg')}");
  padding: calc(70px - 0.75rem) 0;

  ${breakpoints('xs', 'mobile')`
    padding: calc(35px - 0.75rem) 0;
  `}
`;

const Lock = styled.img`
  height: auto;
  max-width: 240px;
  width: auto;
`;

const LockWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;

  ${props => props.position === 1 && css`
    ${breakpoints('xs', 'mobile')`
      margin-bottom: 20px;
    `}
  `}

  ${props => props.position === 2 && css`
    ${breakpoints('xs', 'mobile')`
      margin-bottom: 65px;
    `}
  `}

  ${props => props.position === 3 && css`
    ${Lock} {
      max-width: 65%;
      height: auto;
      width: auto;  
    }
  `}
`;

const LockContentWrapper = styled.div`
  background: ${props => props.theme.primaryInvert};
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.21);
  border-radius: 10px;
  padding: 32px 15px 17px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;  
  text-align: center;
  width: 217px;
  max-width: 217px;
  flex: 0 0 217px;

  ${props => props.position === 1 && css`
    bottom: 73%;
    transform: rotate(-9deg);
  `}

  ${props => props.position === 2 && css`
    bottom: 49%;
    transform: rotate(7deg);

    ${breakpoints('xs', 'mobile')`
      bottom: 61%;
    `}
  `}

  ${props => props.position === 3 && css`
    bottom: 39%;
    transform: rotate(1deg);

    ${breakpoints('xs', 'mobile')`
      bottom: 75%;
    `}
  `}
`;

const LockTitle = styled.h2`
  font-weight: 900;
  font-size: 17px;
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 13px;
`;

const LockContent = styled.div`
  font-size: 13px;
`;

export {
  ProductSectionWrapper,
  Lock,
  LockWrapper,
  LockContentWrapper,
  LockTitle,
  LockContent,
};
