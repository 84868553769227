import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const FormTitle = styled.h3`
  font-weight: 900;
  font-size: 26px;
  margin-bottom: 32px;
  text-align: center;
  color: ${props => props.theme.primary};

  ${breakpoints('xs', 'mobile')`
    font-size: 22px;
  `};
`;

export default FormTitle;
