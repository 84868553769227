import React from 'react';
import PropTypes from 'prop-types';
import Sanitized from 'react-sanitized';

import { Container } from 'react-bulma-components';

import { Title } from 'components/Typography';

import { InfoSectionWrapper, InfoLead, InfoContent } from './ui';

const InfoSection = (props) => {
  const { t } = props;

  return (
    <InfoSectionWrapper>
      <Container className="column">
        <Title centered>
          {t('info-section.title')}
        </Title>
        <InfoLead>
          <Sanitized className="content" html={t('info-section.lead')} wrapperTag="div" />
        </InfoLead>
        <InfoContent>
          <Sanitized className="content" html={t('info-section.content')} wrapperTag="div" />
        </InfoContent>
      </Container>
    </InfoSectionWrapper>
  );
};

InfoSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default InfoSection;
