import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import defaultFetch from 'utils/defaultFetch';

import Hero from 'components/Sections/Hero';
import ClockSection from 'components/Sections/ClockSection';
import TestimonialSlider from 'components/Sections/TestimonialSlider';
import SlideShow from 'components/Sections/SlideShow';
import VideoSection from 'components/Sections/VideoSection';
import ListSection from 'components/Sections/ListSection';
import ProductSection from 'components/Sections/ProductSection';
import InfoSection from 'components/Sections/InfoSection';
import DiscountSection from 'components/Sections/DiscountSection';
import ShippingSection from 'components/Sections/ShippingSection';
import SocialSection from 'components/Sections/SocialSection';
import HistorySection from 'components/Sections/HistorySection';
import RefererSection from 'components/Sections/RefererSection';

import { withTranslation } from '../i18n';

class Home extends React.Component {
  static async getInitialProps() {
    const { data: { 0: { price_categories: priceCategories, price: basePrice } } } = await defaultFetch(`${process.env.BACKEND_URL}/api/products`).then(r => r.json());

    return {
      namespacesRequired: ['home', 'common'],
      priceCategories,
      basePrice
    };
  }

  render() {
    const { t, priceCategories, basePrice } = this.props;

    return (
      <>
        <Head>
          <title>
            {t('common:lock')}
          </title>
        </Head>
        <Hero t={t} priceCategories={priceCategories} basePrice={basePrice} />
        <ClockSection t={t} />
        <TestimonialSlider t={t} />
        <SlideShow t={t} />
        <VideoSection t={t} />
        <ListSection t={t} />
        <ProductSection t={t} />
        <ShippingSection t={t} />
        {/* <DiscountSection t={t} /> */}
        <InfoSection t={t} />
        <SocialSection t={t} />
        <HistorySection t={t} />
        <RefererSection t={t} />
      </>
    );
  }
}

Home.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['home', 'common'])(Home);
