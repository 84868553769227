import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';

const StyledTitle = styled.h3`
  font-weight: 900;
  font-size: 26px;
  margin-bottom: 14px;
  text-align: ${props => (props.centered ? 'center' : 'left')};
  ${props => props.extraStyle && props.extraStyle};
  color: ${props => (props.invert ? props.theme.primaryInvert : props.theme.primary)};

  ${breakpoints('xs', 'mobile')`
    font-size: 22px;
  `};
`;

export default StyledTitle;
